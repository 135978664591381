import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { Offer } from "interfaces/Offer";

export const getOffers = async (
  hashedAppUserId: string,
  operatingSystem: string,
  offset: number = 0,
  limit: number = 10,
) => {
  const params = new URLSearchParams();

  params.append("appUserId", hashedAppUserId);
  params.append("offset", offset.toString());
  params.append("limit", limit.toString());
  params.append("os", operatingSystem);

  const request = `${process.env.API_URL}/offers`;
  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data;
};

export const postAttempt = async (offerId: number, appUid: string) => {
  const request = `${process.env.API_URL}/offers/${offerId}/attempt`;
  const response = await axiosTokenInterceptor.post(request, { appUserId: appUid });

  return response.data;
};

export const getLink = async (offerId: number, appUid: string) => {
  const request = `${process.env.API_URL}/offers/${offerId}/get-link`;
  const response = await axiosTokenInterceptor.post(request, { appUserId: appUid });

  return response.data;
};

export const getTransactions = async (
  hashedAppUserId: string,
  operatingSystem: string,
  offset: number = 0,
  limit: number = 10,
) => {
  const params = new URLSearchParams();

  params.append("appUserId", hashedAppUserId);
  params.append("offset", offset.toString());
  params.append("limit", limit.toString());
  params.append("os", operatingSystem);

  const request = `${process.env.API_URL}/offers/transactions`;
  const response = await axiosTokenInterceptor.get(request, { params });

  return response.data as Offer[];
};

export const getSurveys = async (hashedAppUserId: string, device_id: string) => {
  const params = new URLSearchParams();

  params.append("appUserId", hashedAppUserId);
  params.append("device_id", device_id);

  const request = `${process.env.API_URL}/transactions/${hashedAppUserId}/${device_id}`;
  const response = await axiosTokenInterceptor.get(request);

  return response.data;
};

export const getSummerizedTransactions = async (hashedAppUserId: string, device_id: string) => {
  const request = `${process.env.API_URL}/transactions/amounts/${hashedAppUserId}/${device_id}`;
  const response = await axiosTokenInterceptor.get(request);

  return response.data;
};
